import React from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
interface Props {
  id?: any;
}

const RecaptchaComponent = (Component: any) => {
  const Recaptcha = ({ children }) => {
    return (
      <GoogleReCaptchaProvider
        reCaptchaKey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY}
      >
        {children}
      </GoogleReCaptchaProvider>
    );
  };
  return class Higher extends React.Component<Props> {
    render() {
      return (
        <Recaptcha>
          <Component {...this.props} />
        </Recaptcha>
      );
    }
  };
};
export { RecaptchaComponent };
