import React, { useCallback, useState } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import { Button, Form, Input, notification } from "antd";
import { styled } from "styled-components";
import { firebase, i18n } from "shared";
import { useTranslation } from "react-i18next";
import RestrictedRoute from "../../withRestrictedRoute";
import { useRouter } from "next/router";
import { RecaptchaComponent } from "shared/src/utils/recaptcha";
import Modal from "antd/lib/modal/Modal";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: "#fff";

  & .loginForm {
    max-width: 400px;
    margin-top: -40px;
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
    padding: 34px 24px;
    border-radius: 8px;

    & img {
      margin: 0 auto;
      margin-bottom: 40px;
    }
  }
  & .login-btn {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  & .ant-btn-primary {
    width: 120px;
    height: 38px;
    border-radius: 18px !important;
  }
`;
const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
`;
const ErrorMessgae = styled.div`
  color: #ff6b00;
  font-size: 12px;
`;

const SignIn = () => {
  const { t } = useTranslation();
  const router = useRouter();
  // const { executeRecaptcha } = useGoogleReCaptcha();
  const [loading, setLoading] = useState(false);
  const [resetLoading, setResetLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEmailSent, setIsEmailSent] = useState(false);

  const showModal = () => {
    setIsEmailSent(false);
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .required(t("Please fill in"))
      .email(t("Invalid Email Format.")),
    password: yup.string().required(t("Please fill in")),
  });
  const {
    handleSubmit,
    formState: { errors },
    control,
    watch,
    getValues,
  } = useForm<any, any>({
    resolver: yupResolver(validationSchema),
  });

  const onSubmitHanlder = useCallback(async () => {
    setLoading(true);
    try {
      await firebase
        .auth()
        .signInWithEmailAndPassword(getValues("email"), getValues("password"));
      router.push("/");
    } catch (error) {
      const errorCode = error?.code;

      if (
        errorCode &&
        (errorCode === "auth/user-not-found" ||
          errorCode === "auth/wrong-password")
      ) {
        notification.error({
          message: t("Error Occured"),
          description: t("Email or password doesn't match"),
        });
      } else if (errorCode === "auth/too-many-requests") {
        notification.warning({
          type: "warning",
          message: t("Too many requests. Please wait."),
          description: t(`${error?.message}`),
        });
      } else {
        notification.error({
          message: t(
            "Unable to pass recaptcha verification.Please try again later."
          ),
        });
      }
    }
    setLoading(false);
  }, [watch("email"), watch("password")]);

  const handleSubmitResetForm = async (value: any) => {
    setResetLoading(true);

    try {
      firebase.auth().languageCode = i18n.language;
      await firebase.auth().sendPasswordResetEmail(value?.email);
      setIsEmailSent(true);
    } catch (error) {
      const errorCode = error?.code;
      if (errorCode && errorCode === "auth/too-many-requests") {
        notification.error({
          message: t("Error Occured"),
          description: t("Too many request. please try later"),
        });
      } else {
        notification.error({
          message: t("Error Occured"),
          description: t("Couldn't send reset link.Please try again later."),
        });
      }
    }
    setResetLoading(false);
  };

  return (
    <Container>
      <div className="loginForm">
        <ImageContainer>
          <img src="/logo.svg" width={200} />
        </ImageContainer>

        <Form onFinish={handleSubmit(onSubmitHanlder)} layout="vertical">
          <Controller
            control={control}
            name="email"
            render={({ field }) => (
              <Form.Item label={t("Email Address")}>
                <Input size="large" {...field} />
                {errors.email?.message && (
                  <ErrorMessgae>{errors.email?.message}</ErrorMessgae>
                )}
              </Form.Item>
            )}
          />
          <Controller
            control={control}
            rules={{ required: true }}
            name="password"
            render={({ field }) => (
              <Form.Item label={t("Password")}>
                <Input.Password size="large" {...field} />
                {errors.password?.message && (
                  <ErrorMessgae>{errors.password?.message}</ErrorMessgae>
                )}
              </Form.Item>
            )}
          />
          <div className="login-btn">
            <Button loading={loading} type="primary" htmlType="submit">
              {t("Login")}
            </Button>
            <br />
            <Button onClick={showModal} type="link">
              {t("Forgot password?")}
            </Button>
          </div>
        </Form>
      </div>
      <Modal
        footer={null}
        title={t("Reset Password")}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        {!isEmailSent ? (
          <Form layout="vertical" onFinish={handleSubmitResetForm}>
            <Form.Item
              label={t("Email Address")}
              name="email"
              rules={[
                {
                  required: true,
                  message: t("Please enter"),
                },
                { type: "email", message: t("Invalid Email Format.") },
              ]}
            >
              <Input size="large" type="email" />
            </Form.Item>
            <br />
            <Form.Item>
              <Button loading={resetLoading} type="primary" htmlType="submit">
                {t("Submit")}
              </Button>
            </Form.Item>
          </Form>
        ) : (
          <p>
            {t(
              "We have sent you a password reset email. Please check the contents of the email before setting the password."
            )}
          </p>
        )}
      </Modal>
    </Container>
  );
};
export default RestrictedRoute(RecaptchaComponent(SignIn));
